import { h, Component } from 'preact';
import Carousel from '../Carousel/Carousel';
import MediaSymbol from '../MediaSymbol/MediaSymbol';
import Asset from '../Asset/Asset';
import { Events } from '../../utils/events';
import { isEmpty, omit } from '../../utils/object';
import {
  AssetType,
  MediaSymbolTypes,
  MediaSymbolShape,
  DisplayMode,
  SpinDirection
} from '../../typing/enums';
import { CAROUSEL_DEFAULTS, THUMBNAILS_CONFIG_DEFAULTS } from '../../config/defaults';
import { withContext } from '../../common/context';
import { getAltText } from '../../utils/accessibility';
import { ThumbnailsWrap, MediaSymbolWrapper } from './Thumbnails.styled';
import { SelectedThumbnail } from './SelectedThumbnail';

const isImage = (mediaType, resourceType) =>
  (mediaType === MediaSymbolTypes.VIDEO && resourceType === AssetType.IMAGE) ||
  mediaType === MediaSymbolTypes.IMAGE;

const getTransformation = (resource = {}, config) => {
  const resourceTransformation = resource.thumbnailTransformation;

  if (resource?.videoPlayerSource?.transformation) {
    Object.assign(resourceTransformation, {
      transformation: omit(resource.videoPlayerSource.transformation, ['streaming_profile'])
    });
  }

  return !isEmpty(resourceTransformation)
    ? resourceTransformation
    : !isEmpty(config.selectThumbnailPropsTransformation())
    ? config.selectThumbnailPropsTransformation()
    : !isEmpty(resource.transformation)
    ? resource.transformation
    : config.selectTransformation();
};

export class Thumbnails extends Component {
  get isClockWise() {
    return this.props.context.config.selectSpinPropsSpinDirection() === SpinDirection.CLOCKWISE;
  }

  onItemClick = index => {
    const { config, events } = this.props.context;
    const mediaAssets = config.selectMediaAssets();
    if (this.props.onItemSelect) {
      this.props.onItemSelect(index);
    }
    events(Events.THUMBNAIL_CLICK, mediaAssets[index].publicId);
  };

  onNext = () => {
    const { config, events } = this.props.context;
    const mediaAssets = config.selectMediaAssets();
    events(Events.THUMBNAIL_NEXT, mediaAssets[this.props.selectedIndex].publicId);
  };

  onPrev = () => {
    const { config, events } = this.props.context;
    const mediaAssets = config.selectMediaAssets();
    events(Events.THUMBNAIL_PREV, mediaAssets[this.props.selectedIndex].publicId);
  };

  render(props) {
    const { config } = this.props.context;
    const thumbnailProps = {
      ...THUMBNAILS_CONFIG_DEFAULTS,
      ...config.selectThumbnailProps(),
      ...props
    };
    const position = config.selectThumbnailPropsMediaSymbolPosition();
    const mediaAssets = config.selectMediaAssets();
    const mediaSymbolProps = {
      size: Math.round(config.selectThumbnailPropsWidth() / 2.5),
      ...config.selectMediaSymbolProps()
    };
    const selectedProps = config.selectThumbnailSelectedProps();
    const carouselProps = {
      ...CAROUSEL_DEFAULTS,
      ...config.selectThumbnailsCarouselProps(),
      index: thumbnailProps.index,
      perView: 0,
      onNext: this.onNext,
      onPrev: this.onPrev,
      navigationGutter: thumbnailProps.gutter
    };

    return (
      <Carousel {...carouselProps}>
        {mediaAssets.map((resource, resourceIndex) => {
          const altText = getAltText(config, resource, resourceIndex, mediaAssets.length);

          // Thumbnails - classic & expanded 1-col
          return (
            <ThumbnailsWrap
              key={resourceIndex}
              className="thumbnails-wrap"
              borderColor={config.selectThumbnailPropsBorderColor()}
              borderWidth={config.selectThumbnailPropsBorderWidth()}
              radius={config.selectThumbnailPropsRadius()}
              width={config.selectThumbnailPropsWidth()}
              height={config.selectThumbnailPropsHeight()}
              last={resourceIndex === mediaAssets.length - 1}
              data-test="thumbnails-wrap"
            >
              <Asset
                {...resource}
                focusable={config.selectDisplayPropsMode() == DisplayMode.CLASSIC}
                resourceType={AssetType.IMAGE}
                mediaType={resource.mediaType}
                width={config.selectThumbnailWidth()}
                height={config.selectThumbnailHeight()}
                index={resourceIndex}
                zoom={false}
                inView={true}
                isSelected={thumbnailProps.index === resourceIndex}
                setZoom={thumbnailProps.setZoom}
                onItemClick={this.onItemClick}
                alt={altText}
                transformation={getTransformation(resource, config)}
              />

              {!isImage(resource.mediaType, resource.resourceType) ? (
                <MediaSymbolWrapper
                  {...mediaSymbolProps}
                  noShape={mediaSymbolProps.shape === MediaSymbolShape.NONE}
                  position={position}
                  isClockWise={resource.mediaType === MediaSymbolTypes.SPIN && this.isClockWise}
                  data-test="media-icon-wrap"
                >
                  <MediaSymbol {...mediaSymbolProps} type={resource.mediaType} />
                </MediaSymbolWrapper>
              ) : null}

              {resourceIndex === thumbnailProps.selectedIndex ? (
                <SelectedThumbnail {...selectedProps} />
              ) : null}
            </ThumbnailsWrap>
          );
        })}
      </Carousel>
    );
  }
}

export default withContext(Thumbnails);
