import { Component, h } from 'preact';
import { css } from 'react-emotion';
import { MediaSymbolTypes } from '../../typing/enums';
import { withContext } from '../../common/context';
import Loading from '../Loading/Loading';
import { FlexCenterStretched } from '../../utils/emotion';

class Placeholder extends Component {
  componentDidMount() {
    if (this.props.publicId && this.props.context.config.selectPlaceholderImage()) {
      this.loadPlaceholder(this.props.width, this.props.height);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { width, height } = this.props;
    const nextWidth = nextProps.width || width;
    const nextHeight = nextProps.height || height;
    if (nextWidth / nextHeight !== width / height) {
      this.loadPlaceholder(nextWidth, nextHeight);
    }
  }

  loadPlaceholder(width, height) {
    const loadedWidth = Math.round((width / height) * 32);
    const loadedHeight = Math.round((height / width) * 32);

    const { cacher } = this.props.context;
    cacher(
      this.props.publicId,
      this.getPlaceholderUrl(loadedWidth, loadedHeight),
      loadedWidth,
      loadedHeight,
      this.props.transformation
    ).then(
      asset => {
        this.setState({ url: asset.url });
      },
      () => {}
    );
  }

  getPlaceholderUrl(width, height) {
    const { publicId, transformation, context } = this.props;

    return this.props.mediaType === MediaSymbolTypes.VIDEO
      ? context.cloudinary.getVideoThumbnailUrl(publicId, width, height, transformation)
      : context.cloudinary.getImageUrl(publicId, width, height, transformation);
  }

  render(props, state) {
    const { config } = props.context;

    return (
      <FlexCenterStretched relative>
        {config.selectPlaceholderImage() ? (
          <img
            src={state.url}
            width={props.width}
            height={props.height}
            className={css`
              height: 100% !important;
              width: 100% !important;
              object-fit: contain;
              filter: blur(20px);
            `}
            alt=""
          />
        ) : null}
        <FlexCenterStretched absolute>
          <Loading
            width={props.width}
            height={props.height}
            color={config.selectLoaderPropsColor() || config.selectThemePropsPrimary()}
            size={config.selectLoaderPropsSize()}
            opacity={config.selectLoaderPropsOpacity()}
            style={config.selectLoaderPropsStyle()}
            url={config.selectLoaderPropsUrl()}
          />
        </FlexCenterStretched>
      </FlexCenterStretched>
    );
  }
}

export default withContext(Placeholder);
