import { css } from 'emotion';
import { GalleryNavigationPosition, Navigation } from '../../typing/enums';
import * as E from '../../typing/enums';

export const baseCls = props => css`
  position: absolute;
  z-index: 1;
  top: calc(50% - ${props.size / 2}px);
  opacity: ${props.navigation === Navigation.MOUSE_OVER ? 0 : 1};
  display: ${props.navigation === Navigation.NONE ? 'none' : 'flex'};
  transition: opacity 0.25s ease-in;

  &:focus {
    opacity: 1;
    display: flex;
  }
`;

export const posCls = props => {
  const buttonHalfSize = props.shape === E.ButtonShape.RECTANGLE ? 4 : 2;

  return css`
    ${props.direction}: ${props.position === GalleryNavigationPosition.MIDDLE
      ? ((props.size || 0) / buttonHalfSize) * -1
      : props.position === GalleryNavigationPosition.OFFSET
      ? props.offset
      : 0}px;
  `;
};
