import { h } from 'preact';
import { PureComponent } from 'preact/compat';
import CarouselItem from './CarouselItem/CarouselItem';
import CarouselNavButton from './CarouselNavButton';
import { Wrapper, CarouselWrapper, CarouselInnerWrapper } from './Carousel.emotion';
import { Axis, Transition } from '../../typing/enums';
import { getButtonProps } from '../../common/props';
import { mouseTracker, clickTrigger } from '../../utils/mouse';
import { t } from '../../common/text';

class Carousel extends PureComponent {
  state = {
    index: 0,
    showNext: false,
    showPrev: false,
    dragging: false,
    position: 0
  };

  wrapper = null;

  innerWrapper = null;

  componentDidMount() {
    this.reCalcState(this.getInViewIndex(this.props.index));
  }

  componentWillReceiveProps(nextProps) {
    const isSameAxis = nextProps.axis ? nextProps.axis === this.props.axis : true;
    const isSameIndex = 'index' in nextProps ? nextProps.index === this.props.index : true;
    if (!isSameAxis || isSameIndex) {
      this.removeTransition();
    } else {
      this.addTransition();
    }
    // on mobile - while in zoom since using stoppropagation - mouseup is not called thus not reseting position - hack
    if (nextProps.index !== undefined && nextProps.index !== this.props.index) {
      this.setState({ position: 0 });
    }
  }

  componentDidUpdate(prevProp, prevState) {
    const isPropChanged = Object.keys(prevProp).some(k => prevProp[k] !== this.props[k]);
    const isStateChanged = Object.keys(prevState).some(k => prevState[k] !== this.state[k]);
    if (isPropChanged) {
      setTimeout(() => {
        // hack - clearTimeout doesnt work for some reason when using unmount, instead checking if wrapper exists
        if (this.wrapper) {
          this.reCalcState(this.getInViewIndex(this.props.index));
        }
      }, 0);
    } else if (isStateChanged) {
      this.reCalcState(this.getIndex(this.state.index));
    }

    if (this.props.isZoomed) {
      this.mouseTracker = undefined;
      this.mouseClickTracker = undefined;
    }
  }

  reCalcState(index) {
    const showNext = this.isSlidable(index + 1);
    const showPrev = this.isSlidable(index) && index > 0;
    this.setState({
      index: !this.props.perView && !showNext && !showPrev ? 0 : index,
      showNext: this.isSlidable(index + 1),
      showPrev: this.isSlidable(index) && index > 0
    });
  }

  goToItem = index => {
    const newIndex = this.getIndex(index);
    this.addTransition();
    this.setState({
      index: newIndex,
      showNext: this.isSlidable(newIndex + 1),
      showPrev: newIndex > 0
    });
  };

  nextItem = () => {
    const { index } = this.state;
    if (index < this.props.children.length) {
      this.goToItem(index + 1);
      if (this.props.onNext) {
        this.props.onNext();
      }
    }
  };

  prevItem = () => {
    const { index } = this.state;
    if (index > 0) {
      this.goToItem(index - 1);
      if (this.props.onPrev) {
        this.props.onPrev();
      }
    }
  };

  getNavProps = () => {
    return {
      axis: this.props.axis,
      ...getButtonProps(this.props, 'navigation'),
      float: this.props.navigationFloat
    };
  };

  swipeStart = event => {
    if (this.props.allowSwipe && !this.props.allowSwipe()) {
      return;
    }
    if (this.mouseTracker && !event.isPrimary) {
      // prevent handling multi-touch (i.e. let the browser zoom when pinching)
      this.mouseTracker = undefined;

      return;
    }
    this.mouseTracker = mouseTracker(event, this.props.axis);
    this.mouseClickTracker = clickTrigger(event);
    this.removeTransition();
    this.setState({
      dragging: true,
      position: this.getPosition()
    });
  };

  swipeMove = event => {
    window.requestAnimationFrame(() => {
      this.swipeMoveAnimationFrame(event);
    });
  };

  swipeMoveAnimationFrame = event => {
    if (this.mouseTracker) {
      const tracker = this.mouseTracker(event);
      const itemSize = this.getItemSize();
      const position = this.state.position + tracker.moveBy;
      const nextIndex = Math.floor(position / itemSize) * -1;
      const maxIndex = this.getMaxIndex();
      // https://cloudinary.atlassian.net/browse/CLD-12192 - enable scrolling (vertically) on touch devices
      if (Math.abs(tracker.moveByX) > 0 && Math.abs(tracker.moveByY) < 2) {
        event.preventDefault();
      }
      if (nextIndex <= maxIndex && position < 0) {
        this.setState({ position });
      }
    }
  };

  swipeEnd = event => {
    if (this.mouseClickTracker && this.mouseClickTracker(event)) {
      this.setState({
        dragging: false,
        position: 0
      });
    } else if (this.mouseTracker && this.state.position) {
      const index = this.calcNextIndex(
        this.state.position,
        this.getItemSize(),
        this.getMaxIndex(),
        this.mouseTracker(event).direction
      );
      this.addTransition();
      setTimeout(() => {
        this.setState({
          index,
          dragging: false,
          position: 0
        });
        if (this.props.onItemSwipe) {
          this.props.onItemSwipe(index);
        }
      }, 100);
      this.mouseTracker = undefined;
    }
  };

  addTransition = () => {
    if (this.innerWrapper) {
      this.innerWrapper.style.transition = 'transform 250ms';
    }
  };

  removeTransition = () => {
    if (this.innerWrapper) {
      this.innerWrapper.style.transition = '';
    }
  };

  calcNextIndex = (position, itemSize, maxIndex, direction) => {
    const positionDelta = Math.abs(this.getPosition() - this.state.position);
    if (positionDelta < 35) {
      return this.state.index;
    }
    const index = (position / itemSize) * -1;
    const floorIndex = Math.floor(index);
    const nextIndex =
      direction === 'prev' ? floorIndex : floorIndex === maxIndex ? floorIndex : floorIndex + 1;

    return nextIndex;
  };

  getWrapper() {
    if (!this.wrapper) throw new Error('Wrapper is undefined');

    return this.wrapper;
  }

  getInnerWrapper() {
    if (!this.innerWrapper) throw new Error('Inner Wrapper is undefined');

    return this.innerWrapper;
  }

  getWrapperSize() {
    return this.props.axis === 'horizontal'
      ? this.getWrapper().clientWidth
      : this.getWrapper().clientHeight;
  }

  getInnerWrapperSize() {
    return this.props.axis === 'horizontal'
      ? this.getInnerWrapper().clientWidth
      : this.getInnerWrapper().clientHeight;
  }

  getOverflow(index) {
    const itemSize = this.getItemSize();
    // on first mount wrapper and inner wrapper are undefined
    if (!this.wrapper && !this.innerWrapper) {
      return 0;
    }
    const wrapperSize = this.getWrapperSize();
    const innerWrapperSize = this.getInnerWrapperSize();
    const overflow = wrapperSize - (innerWrapperSize - itemSize * index);

    return overflow;
  }

  getPosition() {
    const { index } = this.state;
    const itemSize = this.getItemSize();
    const overflow = this.getOverflow(index);
    const position =
      overflow > 0 && overflow < itemSize
        ? itemSize * index * -1 + overflow
        : itemSize * index * -1;

    return Math.min(position, 0);
  }

  isSlidable(nextIndex) {
    return nextIndex <= this.getMaxIndex();
  }

  getPerView() {
    const wrapperSize = this.getWrapperSize() + this.props.spacing;
    const itemSize = this.getItemSize();

    return Math.max(Math.floor(wrapperSize / itemSize), 1);
  }

  getMaxIndex() {
    return this.props.children.length - this.getPerView();
  }

  // TODO: check if to add range as a public variable
  getCurrentRange() {
    return {
      startIndex: this.state.index,
      endIndex: this.state.index + this.getPerView() - 1
    };
  }

  isInView(index = 0) {
    const { startIndex = 0, endIndex = 0 } = this.getCurrentRange();

    return index < endIndex && index >= startIndex;
  }

  getIndex(index = 0) {
    if (index >= this.props.children.length || index < 0) {
      return this.state.index;
    }
    const { startIndex = 0 } = this.getCurrentRange();
    const goLeft = index <= startIndex;
    const inViewIndex = this.getInViewIndex(index);

    return goLeft ? index : index <= inViewIndex ? inViewIndex : index;
  }

  getInViewIndex(index = 0) {
    if (this.isInView(index) || index >= this.props.children.length || index < 0) {
      return this.state.index;
    }
    const { startIndex = 0, endIndex = 0 } = this.getCurrentRange();

    return index <= startIndex ? index : index - endIndex + startIndex;
  }

  getItemSize() {
    return this.props.axis === Axis.HORIZONTAL ? this.getItemWidth() : this.getItemHeight();
  }

  getItemWidth() {
    const { itemWidth, axis, spacing } = this.props;

    return itemWidth + (axis === Axis.HORIZONTAL ? spacing : 0);
  }

  getItemHeight() {
    const { itemHeight, axis, spacing } = this.props;

    return itemHeight + (axis === Axis.VERTICAL ? spacing : 0);
  }

  render(props, state) {
    const showNavigation = props.navigation && (state.showPrev || state.showNext);

    const navProps = {
      ...this.getNavProps(),
      wrapWidth: props.itemWidth,
      wrapHeight: props.itemHeight
    };

    return (
      <Wrapper
        className={props.className}
        axis={props.axis}
        itemWidth={props.itemWidth}
        itemHeight={props.itemHeight}
        navigation={props.navigation}
        data-test="carousel"
      >
        {showNavigation && (props.navigationFloat ? state.showPrev : true) && (
          <CarouselNavButton
            {...navProps}
            direction="left"
            disabled={!state.showPrev}
            onClick={this.prevItem}
            gutter={props.navigationGutter}
            aria-label={t('previous')}
          />
        )}
        <CarouselWrapper
          data-test="carousel-wrapper"
          innerRef={el => (this.wrapper = el)}
          // lowercase for safari 12 polyfill
          onpointerdown={this.swipeStart}
          onpointermove={this.state.dragging && this.swipeMove}
          onpointerleave={this.state.dragging && this.swipeEnd}
          onpointercancel={this.state.dragging && this.swipeEnd}
          onpointerup={this.swipeEnd}
          touch-action="pan-y pinch-zoom"
          {...props}
        >
          <CarouselInnerWrapper
            data-test="carousel-inner-wrapper"
            innerRef={el => (this.innerWrapper = el)}
            style={
              props.transition === Transition.SLIDE
                ? {
                    transform:
                      props.axis == 'horizontal'
                        ? `translate3d(${this.state.position || this.getPosition()}px, 0, 0)`
                        : `translate3d(0, ${this.state.position || this.getPosition()}px, 0)`
                  }
                : {}
            }
            {...props}
          >
            {props.children.map((node, i) => {
              const itemProps = {
                spacing: props.spacing,
                width: `${props.itemWidth}px`,
                height: `${props.itemHeight}px`,
                transition: props.transition,
                axis: props.axis,
                onItemClick: props.onItemClick
              };

              return (
                <CarouselItem {...itemProps} key={i} index={i} show={i === props.index}>
                  {node}
                </CarouselItem>
              );
            })}
          </CarouselInnerWrapper>
        </CarouselWrapper>
        {showNavigation && (props.navigationFloat ? state.showNext : true) && (
          <CarouselNavButton
            {...navProps}
            direction="right"
            disabled={!state.showNext}
            onClick={this.nextItem}
            gutter={props.navigationGutter}
            aria-label={t('next')}
          />
        )}
      </Wrapper>
    );
  }
}

export default Carousel;
