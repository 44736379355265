import { createCloudinaryLegacyURL } from '@cloudinary/url-gen';

import produce from 'immer';
import keys from '../utils/keys';
import { Crop, AssetType, MediaSymbolTypes } from '../typing/enums';
import { camelToSnake } from './string';
import { keysFromSnakeToCamel, omit, pick } from './object';
import { sortByKey } from './sort';
import { objectToQuerystring } from '../utils/object';
import { selectQueryParam } from '../common/selectors';

const prepareCloudinaryParams = params =>
  keys('CloudinaryConfig').reduce((newObj, key) => {
    if (params[key]) {
      newObj[camelToSnake(key)] = params[key];
    }

    return newObj;
  }, {});

const prepareAsset = (resource, mediaType, resourceType) => {
  return {
    ...keysFromSnakeToCamel(resource),
    mediaType,
    resourceType
  };
};
const getWidth = width => Math.ceil(width);

const getHeight = height => Math.ceil(height);

const getDpr = (width, height) =>
  width < 1500 && height < 1500 ? Math.round(Math.min(window.devicePixelRatio, 2)) : 1;

const is3dAsset = Asset =>
  Asset.format === 'glb' || Asset.format === 'gltz' || Asset.format === 'fbxz';

const DEFAULT_KEYS = [
  'crop',
  'gravity',
  'background',
  'fetch_format',
  'quality',
  'dpr',
  'duration'
];

const getBaseTransformation = (width, height, assetType = AssetType.IMAGE, transformation = {}) => {
  const baseTransformationValues = pick(transformation, DEFAULT_KEYS);

  const baseTransformation = produce(baseTransformationValues, draft => {
    draft.crop = Object.values(Crop).includes(draft.crop) ? draft.crop : Crop.PAD;
    if (draft.crop === Crop.PAD) {
      draft.background = draft.background || 'auto';
    } else if (draft.crop === Crop.FILL) {
      draft.gravity = draft.gravity || 'auto';
    }
    draft.fetch_format = draft.fetch_format || 'auto';
    if (assetType === AssetType.IMAGE || draft.quality) {
      draft.quality = draft.quality || 'auto';
    }
    if (assetType === AssetType.VIDEO) {
      if (draft.background === 'auto') {
        draft.background = undefined;
      }
      if (draft.gravity === 'auto') {
        draft.gravity = undefined;
      }
      if (draft.fetch_format === 'auto') {
        draft.fetch_format = undefined;
      }
      // Set default duration for PGW videos to prevent issues with on-the-fly
      // transformations of long videos
      draft.duration = draft.duration || 30;
      if (draft.duration === 'auto' || draft.duration === '100p') {
        draft.duration = undefined;
      }
    }

    draft.dpr = draft.dpr || getDpr(getWidth(width), getHeight(height));

    if (width > 0) {
      draft.width = getWidth(width);
    }

    if (height > 0) {
      draft.height = getHeight(height);
    }
  });

  const otherTransformationValues = omit(transformation, DEFAULT_KEYS);

  const reqTransformation = {
    width: baseTransformation.width,
    height: baseTransformation.height,
    crop: baseTransformation.crop
  };

  return {
    transformation: [baseTransformation, otherTransformationValues, reqTransformation]
  };
};

const initCloudinaryCore = config => {
  const cloudConfig = prepareCloudinaryParams(config);

  const queryParam = selectQueryParam(config);

  return {
    getAssetInfo: async publicId => {
      const url = createCloudinaryLegacyURL(publicId, { ...cloudConfig, flags: 'getinfo' });
      // ToDo: cache results
      const res = await fetch(url);

      return res.json();
    },
    getThreeUrl: (publicId, transformation = {}, analytics = {}) => {
      const ignoredTransformations = ['background'];

      const validTransformations = omit(transformation, ignoredTransformations);

      const url = createCloudinaryLegacyURL(publicId, {
        ...cloudConfig,
        transformation: {
          ...validTransformations,
          fetch_format: 'glb'
        }
      });

      return `${url}${objectToQuerystring({ pgw: 1, ...analytics, ...queryParam })}`;
    },
    getImageUrl: (publicId, width, height, transformation = {}, analytics = {}) => {
      const url = createCloudinaryLegacyURL(publicId, {
        ...cloudConfig,
        ...getBaseTransformation(width, height, AssetType.IMAGE, transformation)
      });

      return `${url}${objectToQuerystring({ pgw: 1, ...analytics, ...queryParam })}`;
    },
    getThreeThumbnailUrl: (publicId, width, height, transformation = {}, analytics = {}) => {
      const url = createCloudinaryLegacyURL(publicId, {
        ...cloudConfig,
        ...getBaseTransformation(width, height, AssetType.IMAGE, omit(transformation, ['flags']))
      });

      return `${url}.png${objectToQuerystring({ pgw: 1, tmb: 1, ...analytics, ...queryParam })}`;
    },
    getVideoUrl: (
      publicId,
      width,
      height,
      transformation = {},
      videoPlayerSource = {},
      analytics = {}
    ) => {
      const DEFAULT_VIDEO_SOURCE_TYPES = ['webm', 'mp4', 'ogv'];

      const videoSourceTypes = videoPlayerSource.sourceTypes || DEFAULT_VIDEO_SOURCE_TYPES;
      const videoTransformation = getBaseTransformation(
        width,
        height,
        AssetType.VIDEO,
        transformation
      );

      const videoSrc = createCloudinaryLegacyURL(publicId, {
        ...cloudConfig,
        ...videoTransformation,
        resource_type: 'video'
      });

      return videoSourceTypes.map(
        source =>
          `${videoSrc}.${source}${objectToQuerystring({ pgw: 1, ...analytics, ...queryParam })}`
      );
    },
    getVideoThumbnailUrl: (publicId, width, height, transformation = {}, analytics = {}) => {
      width = Math.ceil(width);
      height = Math.ceil(height);
      const ignoredTransformations = ['streaming_profile'];

      const validTransformations = omit(transformation, ignoredTransformations);

      const url = createCloudinaryLegacyURL(publicId, {
        ...cloudConfig,
        ...getBaseTransformation(width, height, AssetType.IMAGE, validTransformations),
        resource_type: 'video'
      });

      return `${url}${objectToQuerystring({ pgw: 1, tmb: 1, ...analytics, ...queryParam })}`;
    },
    getVideoByTag: async (tag, resourceType = AssetType.VIDEO) => {
      const videosResponse = await fetch(
        createCloudinaryLegacyURL(`${tag}.json`, {
          ...cloudConfig,
          type: 'list',
          resource_type: 'video'
        })
      );
      let videosJson;

      try {
        videosJson = await videosResponse.json();
      } catch (e) {
        console.warn(`Trying to load video resources for tag ${tag} - ${e}`);
        videosJson = { resources: [] };
      }

      const resources = videosJson.resources.map(asset =>
        prepareAsset(asset, MediaSymbolTypes.VIDEO, resourceType)
      );
      if (!resources.length) {
        console.warn(`Gallery widget can't find any media assets for ${tag}`);
      }

      return resources.sort(sortByKey('publicId'));
    },
    getImageByTag: async tag => {
      const imagesResponse = await fetch(
        createCloudinaryLegacyURL(`${tag}.json`, { ...cloudConfig, type: 'list' })
      );
      let imagesJson;
      try {
        imagesJson = await imagesResponse.json();
      } catch (e) {
        console.warn(`Gallery widget is trying to load images resources for tag ${tag} - ${e}`);
        imagesJson = { resources: [] };
      }
      const resources = imagesJson.resources
        .filter(asset => !is3dAsset(asset))
        .map(asset => prepareAsset(asset, MediaSymbolTypes.IMAGE, AssetType.IMAGE));
      if (!resources.length) {
        console.warn(`Gallery widget can't find any media assets for ${tag}`);
      }

      return resources.sort(sortByKey('publicId'));
    },
    getThreeByTag: async tag => {
      const imagesResponse = await fetch(
        createCloudinaryLegacyURL(`${tag}.json`, { ...cloudConfig, type: 'list' })
      );
      let imagesJson;
      try {
        imagesJson = await imagesResponse.json();
      } catch (e) {
        console.warn(`Gallery widget is trying to load 3d resources for tag ${tag} - ${e}`);
        imagesJson = { resources: [] };
      }
      const resources = imagesJson.resources
        .filter(asset => is3dAsset(asset))
        .map(asset => prepareAsset(asset, MediaSymbolTypes.THREE, AssetType.IMAGE));
      if (!resources.length) {
        console.warn(`Gallery widget can't find any media assets for ${tag}`);
      }

      return resources.sort(sortByKey('publicId'));
    }
  };
};

export {
  // Exports
  prepareCloudinaryParams,
  prepareAsset,
  getBaseTransformation,
  initCloudinaryCore
};
