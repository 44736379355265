import styled from 'react-emotion';
import { Axis, Transition } from '../../typing/enums';
import isPropValid from '@emotion/is-prop-valid';

const getWrapperCls = props => {
  switch (props.axis) {
    case Axis.VERTICAL:
      return `
                flex-direction: column;
                width: ${props.itemWidth}px;
                height: 100%;
            `;
    case Axis.HORIZONTAL:
      return `
                width: 100%;
                height: ${props.itemHeight}px;
            `;
    default:
      return ``;
  }
};

export const Wrapper = styled('div')`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  ${props => getWrapperCls(props)};
`;
const getCarouselWrapperCls = props => {
  switch (props.axis) {
    case Axis.VERTICAL:
      return `
                width: ${props.itemWidth}px;
                height: ${
                  props.perView ? `${(props.itemHeight + props.spacing) * props.perView}px` : '100%'
                };
            `;
    case Axis.HORIZONTAL:
      return `
                height: 100%;
                width: ${
                  props.perView ? `${(props.itemWidth + props.spacing) * props.perView}px` : '100%'
                };
            `;
    default:
      return ``;
  }
};

export const CarouselWrapper = styled('div', {
  shouldForwardProp: prop => (isPropValid(prop) || prop == 'touch-action' ? prop : false)
})`
  display: block;
  position: relative;
  overflow: hidden;
  touch-action: ${props => props['touch-action']} !important;
  ${props => getCarouselWrapperCls(props)};
`;
const getCarouselInnerWrapperCls = props => {
  if (props.transition === Transition.SLIDE) {
    switch (props.axis) {
      case 'vertical':
        return `
          flex-direction: column;
          height: ${(props.itemHeight + props.spacing) * props.children.length - props.spacing}px;
          width: 100%;
        `;
      case 'horizontal':
        return `
            width: ${(props.itemWidth + props.spacing) * props.children.length - props.spacing}px;
        `;
      default:
        return ``;
    }
  } else {
    return `
            position: relative;
            height: ${props.itemHeight}px;
        `;
  }
};

//
export const CarouselInnerWrapper = styled('div')`
  display: flex;

  img {
    display: block;
    max-width: 100%;
  }

  ${props => getCarouselInnerWrapperCls(props)};
`;
