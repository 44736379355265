import { h } from 'preact';
import { useContext } from 'preact/compat';
import { Context } from '../App/App';
import { VIEWER_DEFAULTS } from '../../config/defaults';
import { getTransformation } from '../../utils/assetHelpers';
import Asset from '../Asset/Asset';
import ScrollListItems from '../ScrollListItems/ScrollListItems';
import { getAltText } from '../../utils/accessibility';
import { ScrollViewerWrapper } from './ScrollViewer.styled';

const ScrollViewer = props => {
  const { config } = useContext(Context);

  props = { ...VIEWER_DEFAULTS, ...props };

  const columns = config.selectDisplayPropsColumns();
  const spacing = config.selectDisplayPropsSpacing();

  const calcAssetDimensions = () => {
    let { width, height } = props;
    let diviedByColumns = value => (value - spacing * (columns - 1)) / columns;
    if (columns > 1) {
      width = diviedByColumns(width);
      height = diviedByColumns(height);
    }

    return { width, height };
  };

  const calculateIntersectArea = assetHeight => {
    // Small screens/Big assets - 0 margin - some threshold (~0.2-0.5), based on asset/screen ratio
    // Large screens/Small assets - intersection area <= 75% assetHeight, treshold 0.5

    const threshold = Math.min(0.5, window.innerHeight / assetHeight); // <= 0.5
    const intersectionAreaHeight = assetHeight * threshold * 1.5;
    const margin = Math.max(0, window.innerHeight - intersectionAreaHeight); // >= 0

    const marginTop = config.selectDisplayPropsTopOffset();
    const marginBottom = Math.max(0, margin - config.selectDisplayPropsTopOffset());

    return {
      threshold,
      margin: `-${marginTop}px 0px -${marginBottom}px 0px`
    };
  };

  const { width, height } = calcAssetDimensions();
  const intersection = calculateIntersectArea(height);

  const renderAsset = (item, index, viewableItems, setItemRef) => {
    const selectedAsset = props.index;
    const inView = selectedAsset <= index && index <= selectedAsset + (viewableItems - 1);
    const preloadIntersection = selectedAsset + viewableItems * 1.5 >= index;
    const altText = getAltText(config, item, index, props.items.length);

    // Main view - expanded display
    return (
      <Asset
        {...item}
        key={item.assetIndex}
        focusable
        setRef={setItemRef}
        index={index}
        mediaType={item.mediaType}
        resourceType={item.resourceType}
        width={width}
        columns={columns}
        height={height}
        inView={inView}
        preloadIntersection={preloadIntersection}
        useBreakpoint={true}
        zoom={config.selectZoom()}
        zoomState={props.zoomState === index}
        setZoom={props.setZoom}
        spacing={spacing}
        transformation={getTransformation(item.transformation, config)}
        alt={altText}
      />
    );
  };

  return (
    <ScrollViewerWrapper
      data-test="scroll-viewer-wrap"
      borderWidth={config.selectBorderWidth()}
      borderColor={config.selectBorderColor()}
      radius={config.selectRadius()}
    >
      <ScrollListItems
        setSelected={props.setItem}
        itemHeight={height}
        scrollToIndex={props.scrollToIndex}
        items={props.items}
        renderItem={renderAsset}
        intersectionMargin={intersection.margin}
        intersectThreshold={intersection.threshold}
        topOffset={config.selectDisplayPropsTopOffset()}
        columns={columns}
      />
    </ScrollViewerWrapper>
  );
};

export default ScrollViewer;
