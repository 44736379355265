export let DisplayMode;
(function (DisplayMode) {
  DisplayMode['CLASSIC'] = 'classic';
  DisplayMode['EXPANDED'] = 'expanded';
})(DisplayMode || (DisplayMode = {}));

export let Navigation;
(function (Navigation) {
  Navigation['NONE'] = 'none';
  Navigation['ALWAYS'] = 'always';
  Navigation['MOUSE_OVER'] = 'mouseover';
})(Navigation || (Navigation = {}));

export let MediaSymbolPosition;
(function (MediaSymbolPosition) {
  MediaSymbolPosition['TOP_LEFT'] = 'top-left';
  MediaSymbolPosition['TOP_RIGHT'] = 'top-right';
  MediaSymbolPosition['BOTTOM_LEFT'] = 'bottom-left';
  MediaSymbolPosition['BOTTOM_RIGHT'] = 'bottom-right';
  MediaSymbolPosition['CENTER'] = 'center';
})(MediaSymbolPosition || (MediaSymbolPosition = {}));

export let CarouselLocation;
(function (CarouselLocation) {
  CarouselLocation['TOP'] = 'top';
  CarouselLocation['RIGHT'] = 'right';
  CarouselLocation['LEFT'] = 'left';
  CarouselLocation['BOTTOM'] = 'bottom';
})(CarouselLocation || (CarouselLocation = {}));

export let CarouselStyle;
(function (CarouselStyle) {
  CarouselStyle['THUMBNAILS'] = 'thumbnails';
  CarouselStyle['INDICATORS'] = 'indicators';
  CarouselStyle['NONE'] = 'none';
})(CarouselStyle || (CarouselStyle = {}));

export let GalleryNavigationPosition;
(function (GalleryNavigationPosition) {
  GalleryNavigationPosition['INSIDE'] = 'inside';
  GalleryNavigationPosition['MIDDLE'] = 'middle';
  GalleryNavigationPosition['OFFSET'] = 'offset';
})(GalleryNavigationPosition || (GalleryNavigationPosition = {}));

export let Angle;
(function (Angle) {
  Angle['ANGLE_0'] = '0';
  Angle['ANGLE_90'] = '90';
  Angle['ANGLE_180'] = '180';
  Angle['ANGLE_270'] = '-90';
})(Angle || (Angle = {}));

export let MediaSymbolShape;
(function (MediaSymbolShape) {
  //DIAMOND = 'diamond',
  MediaSymbolShape['ROUND'] = 'round';
  MediaSymbolShape['SQUARE'] = 'square';
  MediaSymbolShape['RADIUS'] = 'radius';
  MediaSymbolShape['NONE'] = 'none';
})(MediaSymbolShape || (MediaSymbolShape = {}));

export let MediaSymbolTypes;
(function (MediaSymbolTypes) {
  MediaSymbolTypes['SPIN'] = 'spin';
  MediaSymbolTypes['VIDEO'] = 'video';
  MediaSymbolTypes['IMAGE'] = 'image';
  MediaSymbolTypes['THREE'] = '3d';
  MediaSymbolTypes['NONE'] = 'none';
})(MediaSymbolTypes || (MediaSymbolTypes = {}));

export let Axis;
(function (Axis) {
  Axis['HORIZONTAL'] = 'horizontal';
  Axis['VERTICAL'] = 'vertical';
})(Axis || (Axis = {}));

export let Direction;
(function (Direction) {
  Direction['HORIZONTAL'] = 'horizontal';
  Direction['VERTICAL'] = 'vertical';
})(Direction || (Direction = {}));

export let ButtonShape;
(function (ButtonShape) {
  //DIAMOND = 'diamond',
  ButtonShape['ROUND'] = 'round';
  ButtonShape['SQUARE'] = 'square';
  ButtonShape['RADIUS'] = 'radius';
  ButtonShape['RECTANGLE'] = 'rectangle';
  ButtonShape['NONE'] = 'none';
})(ButtonShape || (ButtonShape = {}));

export let ButtonSizes;
(function (ButtonSizes) {
  ButtonSizes[(ButtonSizes['SM'] = 16)] = 'SM';
  ButtonSizes[(ButtonSizes['MD'] = 32)] = 'MD';
  ButtonSizes[(ButtonSizes['LG'] = 42)] = 'LG';
})(ButtonSizes || (ButtonSizes = {}));

export let Transition;
(function (Transition) {
  Transition['SLIDE'] = 'slide';
  Transition['FADE'] = 'fade';
  Transition['NONE'] = 'none';
})(Transition || (Transition = {}));

export let SelectedStyles;
(function (SelectedStyles) {
  SelectedStyles['ALL'] = 'all';
  SelectedStyles['BORDER'] = 'border';
  SelectedStyles['GRADIENT'] = 'gradient';
})(SelectedStyles || (SelectedStyles = {}));

export let SelectedBorderPosition;
(function (SelectedBorderPosition) {
  SelectedBorderPosition['ALL'] = 'all';
  SelectedBorderPosition['TOP'] = 'top';
  SelectedBorderPosition['TOP_BOTTOM'] = 'top-bottom';
  SelectedBorderPosition['LEFT_RIGHT'] = 'left-right';
  SelectedBorderPosition['BOTTOM'] = 'bottom';
  SelectedBorderPosition['LEFT'] = 'left';
  SelectedBorderPosition['RIGHT'] = 'right';
})(SelectedBorderPosition || (SelectedBorderPosition = {}));

export let IndicatorShape;
(function (IndicatorShape) {
  IndicatorShape['ROUND'] = 'round';
  IndicatorShape['SQUARE'] = 'square';
  IndicatorShape['RADIUS'] = 'radius';
})(IndicatorShape || (IndicatorShape = {}));

export let ZoomViewerPosition;
(function (ZoomViewerPosition) {
  ZoomViewerPosition['TOP'] = 'top';
  ZoomViewerPosition['BOTTOM'] = 'bottom';
  ZoomViewerPosition['LEFT'] = 'left';
  ZoomViewerPosition['RIGHT'] = 'right';
})(ZoomViewerPosition || (ZoomViewerPosition = {}));

export let ZoomType;
(function (ZoomType) {
  ZoomType['INLINE'] = 'inline';
  ZoomType['FLYOUT'] = 'flyout';
  ZoomType['POPUP'] = 'popup';
})(ZoomType || (ZoomType = {}));

export let ZoomButtonPosition;
(function (ZoomButtonPosition) {
  ZoomButtonPosition['TOP_LEFT'] = 'top-left';
  ZoomButtonPosition['TOP_RIGHT'] = 'top-right';
  ZoomButtonPosition['BOTTOM_RIGHT'] = 'bottom-right';
  ZoomButtonPosition['BOTTOM_LEFT'] = 'bottom-left';
})(ZoomButtonPosition || (ZoomButtonPosition = {}));

export let ZoomTrigger;
(function (ZoomTrigger) {
  ZoomTrigger['CLICK'] = 'click';
  ZoomTrigger['HOVER'] = 'hover';
})(ZoomTrigger || (ZoomTrigger = {}));

export let AssetType;
(function (AssetType) {
  AssetType['IMAGE'] = 'image';
  AssetType['VIDEO'] = 'video';
})(AssetType || (AssetType = {}));

export let EaseType;
(function (EaseType) {
  EaseType['LINEAR'] = 'Linear';
  EaseType['QUADRATIC'] = 'Quadratic';
  EaseType['CUBIC'] = 'Cubic';
  EaseType['QUARTIC'] = 'Quartic';
  EaseType['QUINTIC'] = 'Quintic';
  EaseType['SINUSOIDAL'] = 'Sinusoidal';
  EaseType['EXPONENTIAL'] = 'Exponential';
  EaseType['CIRCULAR'] = 'Circular';
  EaseType['ELASTIC'] = 'Elastic';
  EaseType['BACK'] = 'Back';
  EaseType['BOUNCE'] = 'Bounce';
})(EaseType || (EaseType = {}));

export let LoaderPosition;
(function (LoaderPosition) {
  LoaderPosition['TOP_LEFT'] = 'top-left';
  LoaderPosition['TOP_RIGHT'] = 'top-right';
  LoaderPosition['BOTTOM_RIGHT'] = 'bottom-right';
  LoaderPosition['BOTTOM_LEFT'] = 'bottom-left';
  LoaderPosition['CENTER'] = 'center';
})(LoaderPosition || (LoaderPosition = {}));

export let Skin;
(function (Skin) {
  Skin['ADIDAS'] = 'adidas';
  Skin['CA'] = 'ca';
})(Skin || (Skin = {}));

export let Crop;
(function (Crop) {
  Crop['PAD'] = 'pad';
  Crop['FILL'] = 'fill';
  Crop['LIMIT'] = 'limit';
})(Crop || (Crop = {}));

export let PreloadTypes;
(function (PreloadTypes) {
  PreloadTypes['IMAGE'] = 'image';
  PreloadTypes['VIDEO'] = 'video';
  PreloadTypes['SPIN'] = 'spin';
  PreloadTypes['THREE'] = '3d';
})(PreloadTypes || (PreloadTypes = {}));

export let SpinAnimation;
(function (SpinAnimation) {
  SpinAnimation['START'] = 'start';
  SpinAnimation['END'] = 'end';
  SpinAnimation['BOTH'] = 'both';
  SpinAnimation['NONE'] = 'none';
})(SpinAnimation || (SpinAnimation = {}));

export let SpinDirection;
(function (SpinDirection) {
  SpinDirection['CLOCKWISE'] = 'clockwise';
  SpinDirection['COUNTERCLOCKWISE'] = 'counter-clockwise';
})(SpinDirection || (SpinDirection = {}));

export let Sort;
(function (Sort) {
  Sort['NONE'] = 'none';
  Sort['ASC'] = 'asc';
  Sort['DESC'] = 'desc';
})(Sort || (Sort = {}));

export let TipPosition;
(function (TipPosition) {
  TipPosition['TOP'] = 'top';
  TipPosition['CENTER'] = 'center';
  TipPosition['BOTTOM'] = 'bottom';
})(TipPosition || (TipPosition = {}));

export let TipShow;
(function (TipShow) {
  TipShow['ALL'] = 'all';
  TipShow['TOUCH'] = 'touch';
  TipShow['DESKTOP'] = 'desktop';
  TipShow['NEVER'] = 'never';
})(TipShow || (TipShow = {}));

export let ZoomPopupShape;
(function (ZoomPopupShape) {
  ZoomPopupShape['ROUND'] = 'round';
  ZoomPopupShape['SQUARE'] = 'square';
  ZoomPopupShape['RADIUS'] = 'radius';
  ZoomPopupShape['NONE'] = 'none';
})(ZoomPopupShape || (ZoomPopupShape = {}));

export let Controls;
(function (Controls) {
  Controls['DEPRECATED_CONTROLS_ON'] = 'true';
  Controls['DEPRECATED_CONTROLS_OFF'] = 'false';
  Controls['NONE'] = 'none';
  Controls['ALL'] = 'all';
  Controls['PLAY'] = 'play';
})(Controls || (Controls = {}));

export let LoaderStyle;
(function (LoaderStyle) {
  LoaderStyle['CLOUDINARY'] = 'cloudinary';
  LoaderStyle['CIRCLE'] = 'circle';
  LoaderStyle['CUSTOM'] = 'custom';
})(LoaderStyle || (LoaderStyle = {}));

export let MediaAltSource;
(function (MediaAltSource) {
  MediaAltSource['CONTEXTUAL'] = 'contextual';
  MediaAltSource['AUTO'] = 'auto';
  MediaAltSource['METADATA'] = 'metadata';
})(MediaAltSource || (MediaAltSource = {}));

export let SortSource;
(function (SortSource) {
  SortSource['CONTEXTUAL'] = 'contextual';
  SortSource['METADATA'] = 'metadata';
  SortSource['PUBLIC_ID'] = 'public_id';
})(SortSource || (SortSource = {}));

export const VIDEO_PLAYER_TYPE = {
  CLD: 'cloudinary',
  NATIVE: 'native'
};
