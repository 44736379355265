import styled, { css, keyframes } from 'react-emotion';
import { FlexCenterStretched } from '../../utils/emotion';
import tinycolor from 'tinycolor2';

export const LoadingSvg = styled('svg')`
  position: relative;

  path {
    stroke-dasharray: 280;
    stroke-dashoffset: 0;
    transform-origin: center;
  }
`;

export const LoadingWrapper = styled(FlexCenterStretched)`
  .css-loader {
    display: none;
  }
`;

export const dashKeyframes = keyframes`
    0% {
      stroke-dashoffset: 560;
    }
    50% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 560;
    }
  `;

export const spinKeyframes = keyframes`
    100% {
      transform: rotate(360deg);
    }
  `;

export const spinDashKeyframes = keyframes`
   0% {
        stroke-dasharray: 1, 300;
        stroke-dashoffset: 0;
    }
        50% {
        stroke-dasharray: 180, 300;
        stroke-dashoffset: -70;
    }
        100% {
        stroke-dasharray: 180, 300;
        stroke-dashoffset: -248;
    }
`;

export const circleLoaderClassName = opacity => css`
  background: 0 0;
  opacity: ${opacity};
  animation: ${spinKeyframes} 2s linear infinite;

  circle {
    stroke: hsl(210, 70, 75);
    stroke-linecap: round;
    stroke-width: 8;
    animation: ${spinDashKeyframes} 1.5s ease-in-out infinite;
  }
`;
const getColor = color => tinycolor(color).lighten(20).toString();
const getLightColor = color => tinycolor(color).lighten(40).toString();
const colorKeyframes = color => keyframes`
  0% {
    stroke: ${getColor(color)};
  }
  50% {
    stroke: ${getLightColor(color)};
  }
  100% {
    stroke: ${getColor(color)};
  }
`;

export const cldLoaderClassName = props => css`
  path {
    animation: ${dashKeyframes} 2s ease-in-out infinite,
      ${colorKeyframes(props.color)} 8s ease-in-out infinite;
  }

  opacity: ${props.opacity};
`;
