import { getRgbaColor } from '../../utils/color';
import { Direction, SelectedBorderPosition, SelectedStyles } from '../../typing/enums';
import { css, cx } from 'react-emotion';
import { $SelectedWrap } from './Thumbnails.styled';
import { h } from 'preact';

export const SelectedThumbnail = props => {
  const pos = props.borderPosition;
  const borderValue = `${props.borderWidth}px solid ${getRgbaColor(
    props.borderColor,
    props.borderOpacity
  )}`;
  const borderCls =
    props.style !== SelectedStyles.GRADIENT
      ? css`
          ${pos === SelectedBorderPosition.ALL ? `border: ${borderValue};` : ''}
          ${pos === SelectedBorderPosition.BOTTOM ? `border-bottom: ${borderValue};` : ''}
              ${pos === SelectedBorderPosition.TOP ? `border-top: ${borderValue};` : ''}
              ${pos === SelectedBorderPosition.LEFT ? `border-left: ${borderValue};` : ''}
              ${pos === SelectedBorderPosition.RIGHT
            ? `border-right: ${borderValue};`
            : pos === SelectedBorderPosition.LEFT_RIGHT
            ? `border-right: ${borderValue};border-left: ${borderValue};`
            : pos === SelectedBorderPosition.TOP_BOTTOM
            ? `border-top: ${borderValue};border-bottom: ${borderValue};`
            : ''}
        `
      : '';
  const gradientCls =
    props.style !== SelectedStyles.BORDER
      ? css`
          background-image: linear-gradient(
            ${props.gradientDirection === Direction.VERTICAL ? 0 : 90}deg,
            ${getRgbaColor(props.gradientStart, props.gradientOpacity)},
            ${getRgbaColor(props.gradientEnd, props.gradientOpacity)}
          );
        `
      : '';

  return (
    <$SelectedWrap data-test="selected-wrap" className={cx(borderCls, gradientCls)} {...props} />
  );
};
