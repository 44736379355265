import { Axis } from '../typing/enums';

export const mouseTracker = (event, axis = Axis.HORIZONTAL) => {
  let lastMousePos = axis === Axis.HORIZONTAL ? getMouseX(event) : getMouseY(event);
  let lastMousePosX = getMouseX(event);
  let lastMousePosY = getMouseY(event);
  let direction = 'next';

  return event => {
    const newMousePos = axis === Axis.HORIZONTAL ? getMouseX(event) : getMouseY(event);
    const newMousePosX = getMouseX(event);
    const newMousePosY = getMouseY(event);
    const moveBy = newMousePos - lastMousePos;
    const moveByX = newMousePosX - lastMousePosX;
    const moveByY = newMousePosY - lastMousePosY;
    direction =
      newMousePos > lastMousePos ? 'prev' : newMousePos === lastMousePos ? direction : 'next';
    lastMousePos = newMousePos;
    lastMousePosX = newMousePosX;
    lastMousePosY = newMousePosY;

    return {
      moveBy,
      moveByX,
      moveByY,
      direction
    };
  };
};

export const getMouseX = event => {
  return event.touches && event.touches.length
    ? event.touches[0].pageX
    : event.changedTouches && event.changedTouches.length
    ? event.changedTouches[0].pageX
    : event.pageX;
};

export const getMouseY = event => {
  return event.touches && event.touches.length
    ? event.touches[0].pageY
    : event.changedTouches && event.changedTouches.length
    ? event.changedTouches[0].pageY
    : event.pageY;
};

export const clickTrigger = event => {
  let left = 0;
  let top = 0;
  let radiusLimit = 5;
  left = getMouseX(event);
  top = getMouseY(event);

  return event => {
    let deltaX = getMouseX(event) - left;
    let deltaY = getMouseY(event) - top;
    let euclidean = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

    return euclidean < radiusLimit;
  };
};

export const getPosition = (event, axis = 'x') =>
  event.touches
    ? event.touches[0][`page${axis.toUpperCase()}`]
    : event[`client${axis.toUpperCase()}`];
