import { h } from 'preact';
import { Context } from '../components/App/App';

export const withContext = Component => {
  return function ContextComponent(props) {
    return (
      <Context.Consumer>{context => <Component {...props} context={context} />}</Context.Consumer>
    );
  };
};
