import * as enums from '../typing/enums';
import urlParamToJson from './urlParamToJson';
import { RESOURCE_IMAGE_DEFAULTS } from '../config/defaults';

const parseAssetTransformation = transformation =>
  !transformation
    ? {}
    : transformation && typeof transformation === 'string'
    ? urlParamToJson(transformation)
    : transformation;

const prepareSpin = async (asset, cloudinary, transformationProps) => {
  const assets = await cloudinary.getImageByTag(asset.tag);
  const isValidSpinAsset = !!(assets && assets.length);

  return (
    isValidSpinAsset && {
      publicId: asset.tag,
      ...asset,
      ...transformationProps,
      assets,
      mediaType: enums.MediaSymbolTypes.SPIN
    }
  );
};
const prepareImages = async (asset, cloudinary, transformationProps) => {
  const images = [];
  (await cloudinary.getImageByTag(asset.tag)).forEach(imageAsset => {
    images.push({
      altText: asset.altText,
      ...imageAsset,
      ...transformationProps
    });
  });

  return images;
};

const prepareThree = async (asset, cloudinary, transformationProps) => {
  const baseProps = {
    mediaType: enums.MediaSymbolTypes.THREE,
    resourceType: enums.MediaSymbolTypes.THREE,
    altText: asset.altText
  };
  const images = await cloudinary.getThreeByTag(asset.tag);

  return images.map(imageAsset => ({ ...imageAsset, ...baseProps, ...transformationProps }));
};

const prepareVideo = async (asset, cloudinary, transformationProps) => {
  const videos = await cloudinary.getVideoByTag(asset.tag, asset.resourceType);

  const baseProps = {
    altText: asset.altText,
    videoPlayerSource: asset.videoPlayerSource
  };

  return videos.map(videoAsset => ({ ...videoAsset, ...transformationProps, ...baseProps }));
};

export const prepareImageFromPublicIdString = publicId => ({
  ...RESOURCE_IMAGE_DEFAULTS,
  publicId
});

export const prepareByPublicId = asset => {
  if (asset.mediaType === enums.MediaSymbolTypes.THREE) {
    asset.resourceType = enums.MediaSymbolTypes.THREE;
  }
  if (asset.publicId && !asset.mediaType) {
    asset.mediaType = enums.MediaSymbolTypes.IMAGE;
  }
  asset.transformation = parseAssetTransformation(asset.transformation);
  asset.thumbnailTransformation = parseAssetTransformation(asset.thumbnailTransformation);

  return asset;
};

export const prepareAssetFromTag = async (asset, cloudinary) => {
  const assets = [];

  const transformationProps = {
    transformation: parseAssetTransformation(asset.transformation),
    thumbnailTransformation: parseAssetTransformation(asset.thumbnailTransformation)
  };

  if (asset.mediaType === enums.MediaSymbolTypes.IMAGE || !asset.mediaType) {
    const images = await prepareImages(asset, cloudinary, transformationProps);
    images.length && assets.push(...images);
  }

  if (asset.mediaType === enums.MediaSymbolTypes.SPIN) {
    const spin = await prepareSpin(asset, cloudinary, transformationProps);
    spin && assets.push(spin);
  }

  if (asset.mediaType === enums.MediaSymbolTypes.VIDEO) {
    const videos = await prepareVideo(asset, cloudinary, transformationProps);
    videos.length && assets.push(...videos);
  }

  if (asset.mediaType === enums.MediaSymbolTypes.THREE) {
    const three = await prepareThree(asset, cloudinary, transformationProps);
    if (three.length) {
      assets.push(...three);
    }
  }

  return assets;
};
